'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const SliderMarque = {
    els: null,
    init: function () {
        SliderMarque.els = document.querySelectorAll('.edito');
        if (SliderMarque.els && SliderMarque.els.length > 0) {
            SliderMarque.els.forEach(el => {
                SliderMarque.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;

        let modules = "";
        let navigation = null;
        let scrollbar = null;

        if (slider && slider.dataset.navigation === "fleches") {
            swiper = new Swiper(slider, {
                modules: [Navigation, A11y],
                a11y: true,
                spaceBetween: 25,
                slidesPerView: 1.345,
                loop: false,
                speed: 600,
                autoHeight:false,
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                },
                breakpoints: {
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    }
                }
            });

            window.addEventListener("resize", () => SliderMarque.resize(swiper))
            SliderMarque.resize(swiper);
        }
    },

    resize: function(swiper){
        if(window.innerWidth > 991){
            swiper.enable();
        } else {
            swiper.disable();
        }
    }
};

export default SliderMarque;