'use strict';

const Programme =  {
    el:null,
    toggle:null,
    menuItems: null, 
    init: function(){
        let btnProgramme = document.querySelectorAll(".programme_infos_link");
        btnProgramme.forEach(btn => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                let href = btn.dataset.href;
                document.location.href = href;
            })
        })
    }
};

export default Programme;
