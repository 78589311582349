'use strict';

const Header =  {
    el:null,
    toggle:null,
    menuItems: null, 
    init: function(){
        Header.el = document.querySelector('.header');
        Header.menuItems = Header.el.querySelectorAll('.menu-item');
        const anchors = Header.el.querySelectorAll('.menu-item a');
        if(Header.el){
            Header.toggle=Header.el.querySelector('.header__toggle');
            if(Header.toggle){
                Header.toggle.addEventListener('click', () => {
                   Header.el.classList.toggle('navOpened');
                });
            }
            if(Header.menuItems){
                Header.menuItems.forEach(item => {
                    item.addEventListener('click', () => {
                        Header.el.classList.remove('navOpened');  // ferme le menu burger
                    });
                });
            }
        }
    }
};

export default Header;
