import Header from "./components/Header";
import SliderSingle from "./blocks/diaporama_single-slider_image_animee_texte_fixe-slider_image_texte_animes";
import SliderMulti from "./blocks/diaporama_multi";
import Video from "./blocks/video";
import Accordion from "./blocks/accordeon";
import Form from "./components/Form";
import Select from "./components/Select";
import Programme from "./components/Programme";
import SliderMarque from "./blocks/diaporama_marque";


const main = {
    init: function() {
        Header.init();
        SliderSingle.init();
        SliderMulti.init();
        SliderMarque.init();
        Video.init();
        Accordion.init();
        Select.init();
        Form.init();
        Programme.init();
    }
};

export default main